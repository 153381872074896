// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// $gray-dark:                 #cc0000 !default;

$enable-flex: true;

$brand-primary: #191938 !default;

$card-cap-bg: #191938 !default;

$card-cap-color: #ffffff;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$red: #f40000 !default;
$yellow: #e5e500 !default;
$green: #28a745 !default;
$cyan: #17a2b8 !default;

$theme-colors: (
	primary: #191938,
	secondary: #32324e,
	success: $green,
	info: $cyan,
	warning: #d9b310,
	danger: $red,
	light: $gray-100,
	dark: #1d2731,
) !default;

$navbar-light-hover-color: #f4f4f4;

$brand-primary: #000 !default;

$card-cap-bg: map-get($theme-colors, 'primary') !default;

$card-cap-color: #ffffff;

$navbar-top-background: map-get($theme-colors, 'primary') !default;
$navbar-bottom-background: map-get($theme-colors, 'primary') !default;

$navbar-top-color: #fff;
$navbar-top-active-color: #fff;
$navbar-top-hover-color: #ddd;

$navbar-bottom-color: #fff;
$navbar-bottom-active-color: #fff;
$navbar-bottom-hover-color: #ddd;

@import '~bootstrap/scss/bootstrap.scss';

@import '../../../../vendor/z5internet/demora/src/resources/assets/scss/scss.scss';

body {
	padding-top: 77px !important;
	padding-bottom: 0px !important;
}

#pageContent {
	min-height: 80vh;
}

.navbarBottom {
	position: relative;
}
body .navbarTop {
	height: 72px;
}

.modal-full-width {
	max-width: 100% !important;
	margin: 30px 10px !important;
}

.dashboard-side-col {
	display: block;
	max-width: 100%;
	flex: 0 0 100%;
	min-height: 300px;
	display: flex;
}

.dashboard-side-col {
	flex-wrap: wrap;
}

.row-dashboard-side-col > .col {
	overflow: hidden;
}

@media (min-width: 1206px) {
	.dashboard-side-col {
		flex: 0 0 350px;
		max-width: 350px;
		min-height: 800px;
	}

	.row-dashboard-side-col {
		flex-wrap: nowrap !important;
	}
}

@media (min-width: 800px) {
	.menu-open #pageContent {
		padding-right: 220px;
	}
}
