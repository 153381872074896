.menu-open #footer {
	transform: translate3d(220px, 0px, 0px);
}

#footer {
	color: #fff;
	font-size: 14px;

	transition: all 0.5s ease 0s;
	a {
		color: #ffffff;
	}

	a:hover {
		color: #ffffff;
	}

	.footer-top {
		padding: 60px 0 0px 0;

		.footer-info {
			margin-bottom: 30px;

			h3 {
				font-size: 26px;
				margin: 0 0 10px 0;
				padding: 2px 0 2px 0;
				line-height: 1;
				font-weight: 600;
				letter-spacing: 3px;
				color: #9fc658;
			}

			p {
				font-size: 14px;
				line-height: 24px;
				margin-bottom: 0;
				font-family: 'Raleway', sans-serif;
				color: #fff;
			}
		}

		.social-links a {
			font-size: 18px;
			display: inline-block;
			background: #3c4133;
			color: #fff;
			line-height: 1;
			padding: 8px 0;
			margin-right: 4px;
			border-radius: 50%;
			text-align: center;
			width: 36px;
			height: 36px;
			transition: 0.3s;

			&:hover {
				background: #94c045;
				color: #fff;
				text-decoration: none;
			}
		}

		h4 {
			font-size: 14px;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			position: relative;
			padding-bottom: 12px;

			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				height: 2px;
			}

			&::before {
				right: 0;
				background: #3c4133;
			}

			&::after {
				background: #94c045;
				width: 60px;
			}
		}

		.footer-links {
			margin-bottom: 30px;

			ul {
				list-style: none;
				padding: 5px 0 0 0;
				margin: 0;

				li {
					padding: 0 0 15px 0;
				}

				a {
					color: #fff;
					transition: 0.3s;

					&:hover {
						color: #94c045;
					}
				}
			}
		}

		.footer-contact {
			margin-bottom: 30px;

			p {
				line-height: 26px;
			}
		}

		.footer-newsletter {
			margin-bottom: 30px;

			input {
				&[type='email'] {
					border: 0;
					padding: 6px 8px;
					width: 65%;
					border-radius: 4px 0 0 4px;
				}

				&[type='submit'] {
					background: #94c045;
					border: 0;
					border-radius: 0 4px 4px 0;
					width: 35%;
					padding: 6px 0;
					text-align: center;
					color: #fff;
					transition: 0.3s;
					cursor: pointer;

					&:hover {
						background: #789d35;
					}
				}
			}
		}
	}

	.copyright {
		text-align: center;
		padding-top: 30px;
	}

	.credits {
		padding-top: 10px;
		text-align: center;
		font-size: 13px;
		color: #fff;
	}
}
